import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

import { THE_BRIDE } from '@/constants';
import { BG_DESKTOP_VIEW } from '@/constants/assets';
import WithAnimation from '@/components/Common/WithAnimation';

function LeftContent() {
  return (
    <Box
      bgColor="bgPrimary"
      width="calc(100% - 500px)"
      height="100%"
      pos="fixed"
      bgImage={BG_DESKTOP_VIEW}
      bgPos="center"
      bgSize="cover"
      borderRight="8px solid"
      borderRightColor="blackAlpha.400"
      color="white"
    >
      <Box
        padding="42px"
        bgImage="linear-gradient(to bottom, #00000063 60%, transparent)"
      >
        <WithAnimation>
          <Text letterSpacing="4px" fontSize="xs">
            THE WEDDING OF
          </Text>
        </WithAnimation>
        <WithAnimation>
          <Heading
            margin="32px 0"
            fontWeight="normal"
            size="4xl"
            letterSpacing="2px"
            lineHeight="80%"
            dangerouslySetInnerHTML={{ __html: THE_BRIDE }}
          />
        </WithAnimation>
        <WithAnimation>
          <Text fontStyle="italic">
            “But above all these things put on love, which is the bond of perfection.” <br />- Colossians 3:14 NKJV
          </Text>
        </WithAnimation>

      </Box>
    </Box>
  );
}

export default LeftContent;
