import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { node } from 'prop-types';
import { Box } from '@chakra-ui/react';

import useIsDesktopView from '@/hooks/useIsDesktopView';

import { DEFAULT_LANGUAGE } from '@/constants/feature-flags';
import { SEO_IMAGE, SEO_URL, SEO_DESCRIPTION, SEO_TITLE } from '@/constants';

import DekstopView from './LeftContent';
import ImgFavicon from './assets/favicon.png';
import { styDesktopView } from './styles';

/**
 * Custom CSS styles for specific components
 * @important - don't override or change it
 */
import 'react-image-lightbox/style.css';
import './assets/styles.css';

function MainLayout({ children }) {
  const { isDesktopView } = useIsDesktopView();

  return (
    <Fragment>
      <Helmet htmlAttributes={{ lang: DEFAULT_LANGUAGE, translate: 'no' }}>
        <title>{SEO_TITLE}</title>

        <link rel="icon" type="image/png" href={ImgFavicon} />

        <meta property="og:title" content={SEO_TITLE} />
        <meta property="og:image" content={SEO_IMAGE} />
        <meta property="og:url" content={SEO_URL} />
        <meta property="og:site_name" content={SEO_TITLE} />
        <meta property="og:description" content={SEO_DESCRIPTION} />
        <meta name="twitter:title" content={SEO_TITLE} />
        <meta name="twitter:description" content={SEO_DESCRIPTION} />
        <meta name="twitter:image" content={SEO_IMAGE} />
        <meta name="twitter:url" content={SEO_URL} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content="@idindrakusuma" />
        <meta name="color-scheme" content="dark light" />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
        <link href="https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&display=swap" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&display=swap" rel="stylesheet" />
      </Helmet>
      <Box id="page" className={styDesktopView}>
        <Box className="desktop-view__left-side">{isDesktopView && <DekstopView />}</Box>
        <Box className="desktop-view__right-side">
          <Box boxShadow="2xl">{children}</Box>
        </Box>
      </Box>
    </Fragment>
  );
}

MainLayout.propTypes = {
  children: node.isRequired,
};

export default MainLayout;
